import { useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from 'next-i18next'

export default function BlogNavbar() {
  const { t } = useTranslation('common')

  const router = useRouter()
  const [isActive, setIsActive] = useState('')

  useEffect(() => {
    if (router.pathname === '/careers') {
      setIsActive('careers')
    } else {
      setIsActive('open-roles')
    }
  }, [router]);

  return (
    <>
      <nav className="py-4 flex items-center md:justify-end relative">
        <Link href="/projects">
          <h5 className="w-fit absolute top-4 left-14 md:left-5 cursor-pointer z-[2]">{t('careers', {ns: 'common'})}</h5>
        </Link>
        <div className="relative pr-14 md:pr-5 w-full">
          <ul className={`flex-1 m-0 pl-40 flex z-[2] items-center justify-center lg:justify-end gap-x-8 flex-wrap list-none`}>
            <Link href={`/careers`}>
              <li dangerouslySetInnerHTML={{ __html: 'Career at CyStack' }} className={`${isActive === 'careers' ? 'font-semibold before:w-full opacity-100' : 'before:w-0 opacity-70'} text-body4 cursor-pointer relative before:absolute before:-bottom-1 before:content-[''] before:bg-black before:h-[2px] before:w-0 hover:before:w-full before:transition-all hover:opacity-100 mb-6`}></li>
            </Link>
            <Link href={`/careers/open-roles`}>
              <li dangerouslySetInnerHTML={{ __html: 'Open positions' }} className={`${isActive === 'open-roles' ? 'font-semibold before:w-full opacity-100' : 'before:w-0 opacity-70'} text-body4 cursor-pointer relative before:absolute before:-bottom-1 before:content-[''] before:bg-black before:h-[2px] before:w-0 hover:before:w-full before:transition-all hover:opacity-100 mb-6`}></li>
            </Link>
          </ul>
        </div>
      </nav>
    </>
  )
}