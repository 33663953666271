import { Modal } from "antd";
import IconClose from "@/public/icons/Close.svg";

export default function ModalJoinCyStackCommunity({ isOpen, closeModal }) {
  return (
    <Modal
      open={isOpen}
      footer={null}
      wrapClassName="form-join-community"
      closeIcon={<IconClose className="text-white" />}
      rootClassName="p-0"
      maskClosable={false}
      onCancel={closeModal}
    >
      <div className="relative max-w-full">
        <div className="absolute -translate-x-1/2 left-1/2 top-20 z-[2] md:max-w-[105%] sm:flex flex-col items-center">
          <img
            src="/images/CystackCommunity/HeaderTitle.svg"
            alt="CyStack join community"
            className="sm:max-w-[400px]"
          />
          <img
            src="/images/CystackCommunity/Header.svg"
            className="-mt-[84px] max-w-full sm:-mt-16 sm:max-w-[350px]"
            alt="CyStack join community"
          />
        </div>

        <img
          src="/images/CystackCommunity/MainBackground.svg"
          alt="CyStack join community"
          className="w-[410px] md:max-w-full md:w-auto"
        />
      </div>
      <a
        href="https://www.facebook.com/groups/cystack"
        target="_blank"
        rel="nofollow noopener"
      >
        <img
          className="max-w-full cursor-pointer"
          src="/images/CystackCommunity/FooterAction.svg"
          alt="CyStack join community"
        />
      </a>
    </Modal>
  );
}
