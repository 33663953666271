import axios from 'axios';
// import * as Sentry from "@sentry/nextjs";

// Request but return full response, unlike normal request just return data
export const requestFull = axios.create({
  withCredentials: false,
  timeout: 30000, // Set timeout to 5 seconds
  headers: {
    'Content-Type': 'application/json', // Set content type to JSON
  },
});

// Add a response interceptor
requestFull.interceptors.response.use(
  response => {
    // Handle successful responses
    return response;
  },
  errorHandler
);

export function errorHandler(error) {
  // Sentry.captureException(error);
  let ignored = false

  if (error.response) {
    // Ignore WP maintainance
    if (error.response.data?.message?.includes('scheduled maintenance')) {
      console.error('WP maintainance')
      ignored = true
    }

    // Ignore 400, but still log reason to debug, but dont log if wp maintainance
    if (error.response.status === 400 && !ignored) {
      console.error('Error status', error.response.status);
      console.error('Error from', error.config?.url);
      console.error('Error data', error.response.data);
      ignored = true
    }

    // Ignore 403
    if (error.response.status === 403) {
      console.error('Request forbidden', error.config?.method, error.config?.url)
      ignored = true
    }

    // Ignore 404
    if (error.response.status === 404) {
      ignored = true
    }

    // Ignore 429
    if (error.response.status === 429) {
      console.error('Request was throttled', error.config?.method, error.config?.url)
      ignored = true
    }

    // Ignore 521
    if (error.response.status === 521) {
      console.error('Error status 521', error.config?.method, error.config?.url)
      ignored = true
    }
    
    if (!ignored) {
      console.error('Error status', error.response.status);
      console.error('Error data', error.response.data);
    }
  } else if (error.request) {
    // Ignore timeout
    if (error.message.includes('timeout of')) {
      console.error('Request timeout', error.config?.method, error.config?.url)
      ignored = true
    }

    if (!ignored) {
      console.error('No response received', error.config || error.request);
    }
  } else {
    console.error('Request error', error.message);
  }

  if (ignored) {
    error.message = `SENTRY_IGNORE_${error.message}`
  }
  return Promise.reject(error);
}