import { Button } from "antd";
import IconClose from "@/public/icons/Close.svg";
import Link from "next/link";
import styled from "styled-components";
import { getCookie, setCookie } from "cookies-next";
import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";

const Wrapper = styled.div`
  box-shadow: 0px -208px 83px rgba(0, 0, 0, 0.01),
    0px -117px 70px rgba(0, 0, 0, 0.03), 0px -52px 52px rgba(0, 0, 0, 0.05),
    0px -13px 29px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06);
`;

export default function AcceptCookies() {
  const { t } = useTranslation("common");
  const [isAcceptCookies, setIsAcceptCookies] = useState(true);
  useEffect(() => {
    if (getCookie("accept-cookies") === undefined) {
      setIsAcceptCookies(false);
    } else {
      setIsAcceptCookies(getCookie("accept-cookies"));
    }
  }, []);
  const [isClose, setIsClose] = useState(false);

  const handleAcceptCookies = () => {
    setCookie("accept-cookies", true, { maxAge: 99999999 });
    setIsAcceptCookies(true);
  };
  return (
    <>
      <Wrapper className={` relative bg-white`}>
        <div className={`${(Boolean(isAcceptCookies) || isClose) && "hidden"}`}>
          <IconClose
            className="absolute top-7 right-8 w-[22px] h-[22px] object-contain cursor-pointer hover:text-red-500"
            onClick={() => {
              setIsClose(true);
            }}
          />
          <div className="container py-[68px]">
            <div className="flex justify-between">
              <div className="text-dark max-w-[740px] lg:max-w-[480px]">
                <h5 className="mb-6">{t("accept_cookies.title")}</h5>
                <p className="text-body3">{t("accept_cookies.desc")}</p>
              </div>
              <div className="max-w-[270px]">
                <Button
                  size={"large"}
                  type="primary"
                  block
                  className="mb-3"
                  onClick={handleAcceptCookies}
                >
                  {t("button.accept")}
                </Button>
                <Link href="/privacy">
                  <Button size={"large"} type="primary" block ghost>
                    {t("button.learn_more")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
