import { Button } from "antd";
import ArrowRightUpLine from "@/public/icons/ArrowRightUpLine.svg";
import Link from "next/link";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";

export default function SubmenuProducts() {
  const { t } = useTranslation("header");

  const Tag = ({ type }) => {
    const tagInfo = useMemo(() => {
      switch (type) {
        case "popular":
          return {
            label: t("popular", { ns: "common" }),
            color: "#B42318",
            borderColor: "#FECDCA",
            backgroundColor: "#FEF3F2",
          };
        case "new":
          return {
            label: t("new", { ns: "common" }),
            color: "#067647",
            borderColor: "#ABEFC6",
            backgroundColor: "#ECFDF3",
          };
        default:
          return {
            label: "",
            color: "#067647",
            borderColor: "#ABEFC6",
            backgroundColor: "#ECFDF3",
          };
      }
    }, [type]);

    return (
      <span
        className={`inline-block py-[2px] px-2 rounded-full border border-solid leading-[18px]`}
        style={tagInfo}
      >
        {tagInfo.label}
      </span>
    );
  };

  const products = [
    {
      icon: "/images/layout/products/VulnScan.svg",
      title: t("submenu_products.item_1.title"),
      description: t("submenu_products.item_1.description"),
      link: "/vulnscan",
      tag: "popular",
    },
    {
      icon: "/images/layout/products/LockerSecret.svg",
      title: t("submenu_products.item_2.title"),
      description: t("submenu_products.item_2.description"),
      link: "https://locker.io/secrets",
      tag: "new",
    },
    {
      icon: "/images/layout/products/SafeChain.svg",
      title: t("submenu_products.item_3.title"),
      description: t("submenu_products.item_3.description"),
      link: "https://safechain.org/",
      externalLink: true,
    },
    {
      icon: "/images/layout/products/LockerPassword.svg",
      title: t("submenu_products.item_4.title"),
      description: t("submenu_products.item_4.description"),
      link: "https://locker.io/passwords",
      externalLink: true,
    },
    {
      icon: "/images/layout/products/CystackEndpoint.svg",
      title: t("submenu_products.item_5.title"),
      description: t("submenu_products.item_5.description"),
      link: "/endpoint",
      tag: "new",
    },
    {
      icon: "/images/layout/products/Whitehub.svg",
      title: t("submenu_products.item_6.title"),
      description: t("submenu_products.item_6.description"),
      link: "https://whitehub.net/",
      externalLink: true,
    },
    {
      icon: "/images/layout/products/CystackTrustCenter.svg",
      title: t("submenu_products.item_7.title"),
      description: t("submenu_products.item_7.description"),
      link: "/trust-center",
    },
    {
      icon: "/images/layout/products/DataLeak.svg",
      title: t("submenu_products.item_8.title"),
      description: t("submenu_products.item_8.description"),
      link: "/data-leak-detection",
    },
  ];
  return (
    <div className="flex bg-white md:flex-col md:max-h-[calc(100dvh-210px)] overflow-y-auto">
      <div className="flex flex-col max-w-[415px] md:max-w-full flex-shrink-0 gap-8 p-8 text-white bg-[#0E0E6B] lg:h-fit">
        <img
          src="/images/layout/products/Products.svg"
          className="object-contain object-center w-full"
          alt="CyStack Intelligent Products"
        />
        <div>
          <h4 className="mb-3 font-semibold">{t("submenu_products.title")}</h4>
          <p className="leading-6">{t("submenu_products.description")}</p>
        </div>
        <Link href={"/platform"}>
          <Button
            type={"primary"}
            className="bg-[#6172F3] border-[#8098F9] flex items-center justify-center"
            block
          >
            <span>{t("submenu_products.action")}</span>
            <ArrowRightUpLine />
          </Button>
        </Link>
      </div>
      <div className="grid flex-1 grid-cols-2 lg:grid-cols-1 gap-4 p-8 xl:p-4 xl:gap-2 lg:max-h-[calc(100dvh-140px)] md:max-h-full">
        {products.map((item) => (
          <div key={item.title} className="flex col-span-1 gap-4 p-3">
            <img
              src={item.icon}
              className="object-cover object-center w-6 h-6"
              alt="CyStack Product platform"
            />
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2 cursor-pointer ">
                {item.externalLink ? (
                  <a href={item.link} target="_blank">
                    <h4 className="font-semibold leading-6 text-body3 text-dark-title hover:underline">
                      {item.title}
                    </h4>
                  </a>
                ) : (
                  <Link href={item.link}>
                    <h4 className="font-semibold leading-6 text-body3 text-dark-title hover:underline">
                      {item.title}
                    </h4>
                  </Link>
                )}
                {item.tag ? <Tag type={item.tag} /> : null}
              </div>
              <p className="leading-5 text-[14px] text-grey">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
