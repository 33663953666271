import { useTranslation } from "next-i18next";
import Link from "next/link";

export default function SubmenuCompany() {
  const { t } = useTranslation("header");
  const companies = [
    {
      avatar: "/images/layout/company/AboutUs.svg",
      label: t("company.about_us", { ns: "common" }),
      link: "/about",
    },
    {
      avatar: "/images/layout/company/BrandKit.svg",
      label: t("company.brand", { ns: "common" }),
      link: "/brand",
    },
    {
      avatar: "/images/layout/company/Press.svg",
      label: t("company.press", { ns: "common" }),
      link: "/press",
    },
    {
      avatar: "/images/layout/company/Careers.svg",
      label: t("careers", { ns: "common" }),
      link: "/careers/open-roles",
      hiring: true,
    },
    {
      avatar: "/images/layout/company/ContactUs.svg",
      label: t("company.contact_us", { ns: "common" }),
      link: "/contact",
    },
  ];
  return (
    <div className="gap-4 p-8">
      <ul className="p-0">
        {companies.map((item, i) => {
          return (
            <Link key={i} href={item.link || "/"}>
              <li className="flex items-center p-3 mb-2 cursor-pointer gap-x-6 group text-body4 last:mb-0">
                <img
                  src={item.avatar}
                  className="object-contain w-6 h-6"
                  alt="CyStack image avatar product"
                />
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-[16px] leading-6 group-hover:underline text-dark-title">
                    {item.label}
                  </span>
                  {item.hiring ? (
                    <span
                      className={`inline-block py-[2px] px-2 rounded-full border border-solid leading-[18px] bg-[#EFF8FF] border-[#B2DDFF] text-[#175CD3]`}
                    >
                      {t("company.hiring", { ns: "common" })}
                    </span>
                  ) : null}
                </div>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
