import { Button } from "antd";
import ArrowRightUpLine from "@/public/icons/ArrowRightUpLine.svg";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export default function SubmenuSolutions() {
  const { t } = useTranslation("header");
  const solutions = [
    {
      title: t("solutions.by_use_case", { ns: "common" }),
      items: [
        {
          avatar: "/images/layout/solutions/Startup.svg",
          label: t("solutions.startup", { ns: "common" }),
          link: "/industries/startup",
        },
        {
          avatar: "/images/layout/solutions/Compliance.svg",
          label: t("solutions.degree_13_compliance", { ns: "common" }),
          link: "/solutions/giai-phap-tuan-thu-nghi-dinh-13-cho-doanh-nghiep",
        },
        {
          avatar: "/images/layout/solutions/Ransomware.svg",
          label: t("solutions.ransomware", { ns: "common" }),
          link: "/solutions/ransomware",
        },
        {
          avatar: "/images/layout/solutions/SecurityAudit.svg",
          label: t("solutions.security_audit", { ns: "common" }),
          link: "/solutions/security-audit",
        },
        {
          avatar: "/images/layout/solutions/SecurityOperations.svg",
          label: t("solutions.security_operations", { ns: "common" }),
          link: "/solutions/security-operations",
        },
        {
          avatar: "/images/layout/solutions/DataSecurity.svg",
          label: t("solutions.data_security", { ns: "common" }),
          link: "/solutions/data-security",
        },
        {
          avatar: "/images/layout/solutions/SecurityCompliance.svg",
          label: t("solutions.security_compliance", { ns: "common" }),
          link: "/solutions/security-compliance",
        },
        {
          avatar: "/images/layout/solutions/BlockchainSecurity.svg",
          label: t("solutions.blockchain_security", { ns: "common" }),
          link: "/solutions/blockchain-security",
        },
      ],
    },
    {
      title: t("solutions.by_industry", { ns: "common" }),
      items: [
        {
          avatar: "/images/layout/solutions/PublicSector.svg",
          label: t("solutions.public_sector", { ns: "common" }),
          link: "/industries/public-sector",
        },
        {
          avatar: "/images/layout/solutions/Financial.svg",
          label: t("solutions.financial_services", { ns: "common" }),
          link: "/industries/financial-services",
        },
        {
          avatar: "/images/layout/solutions/Ecommerce.svg",
          label: t("solutions.ecommerce", { ns: "common" }),
          link: "/industries/ecommerce",
        },
        {
          avatar: "/images/layout/solutions/Healthcare.svg",
          label: t("solutions.healthcare", { ns: "common" }),
          link: "/industries/healthcare",
        },
        {
          avatar: "/images/layout/solutions/Blockchain.svg",
          label: t("solutions.blockchain_companies", { ns: "common" }),
          link: "/industries/blockchain",
        },
      ],
    },
  ];
  return (
    <div className="flex bg-white md:flex-col md:max-h-[calc(100dvh-210px)] overflow-y-auto">
      <div className="grid flex-1 grid-cols-2 lg:grid-cols-1 gap-4 p-8 xl:p-4 xl:gap-2 lg:max-h-[calc(100dvh-140px)] md:max-h-full">
        {solutions.map((p, index) => (
          <div key={index} className="col-span-1">
            <h4 className="text-grey text-[16px] mb-6 tracking-wide font-medium px-3">
              {p.title}
            </h4>
            <ul className="p-0">
              {p.items.map((item, i) => {
                return (
                  <Link key={i} href={item.link || "/"}>
                    <li className="flex items-center p-3 mb-2 cursor-pointer gap-x-6 group text-body4 last:mb-0">
                      <img
                        src={item.avatar}
                        className="object-contain w-6 h-6"
                        alt="CyStack image avatar product"
                      />
                      <span className="font-semibold text-[16px] leading-6 group-hover:underline text-dark-title">
                        {item.label}
                      </span>
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col max-w-[480px] md:max-w-full flex-shrink-0 gap-8 p-8 text-white bg-[#0E0E6B] lg:h-fit">
        <img
          src="/images/layout/solutions/Solutions.svg"
          className="object-contain object-center w-full"
          alt="CyStack Intelligent Products"
        />
        <div>
          <h4 className="mb-3 font-semibold">{t("submenu_solutions.title")}</h4>
          <p className="leading-6">{t("submenu_solutions.description")}</p>
        </div>
        <Link href={"/blog"}>
          <Button
            type={"primary"}
            className="bg-[#6172F3] border-[#8098F9] flex items-center justify-center"
            block
          >
            <span>{t("submenu_solutions.action")}</span>
            <ArrowRightUpLine />
          </Button>
        </Link>
      </div>
    </div>
  );
}
