import { useTranslation } from "next-i18next";
import Link from "next/link";

export default function SubmenuResources() {
  const { t } = useTranslation("header");
  const resources = [
    {
      avatar: "/images/layout/resources/Blog.svg",
      label: t("resources.blog", { ns: "common" }),
      link: "/blog",
    },
    {
      avatar: "/images/layout/resources/CaseStudies.svg",
      label: t("customers.case_study", { ns: "common" }),
      link: "/customers",
    },
    {
      avatar: "/images/layout/resources/Research.svg",
      label: t("resources.research", { ns: "common" }),
      link: "/research",
    },
    {
      avatar: "/images/layout/resources/HelpCenter.svg",
      label: t("resources.help_center", { ns: "common" }),
      link: "/",
    },
    {
      avatar: "/images/layout/resources/Projects.svg",
      label: t("customers.projects", { ns: "common" }),
      link: "/projects",
    },
    {
      avatar: "/images/layout/solutions/Startup.svg",
      label: t("resources.library", { ns: "common" }),
      link: "/library",
    },
  ];
  return (
    <div className="gap-4 p-8 border">
      <ul className="p-0">
        {resources.map((item, i) => {
          return (
            <Link key={i} href={item.link || "/"}>
              <li className="flex items-center p-3 mb-2 cursor-pointer gap-x-6 group text-body4 last:mb-0">
                <img
                  src={item.avatar}
                  className="object-contain w-6 h-6"
                  alt="CyStack image avatar product"
                />
                <span className="font-semibold text-[16px] leading-6 group-hover:underline text-dark-title">
                  {item.label}
                </span>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
