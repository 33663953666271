import "../styles/index.scss";

import { useEffect, useState } from "react";

import CareersNavbar from "@/components/careers/Navbar";
import { ConfigProvider } from "antd";
import CustomerNavbar from "@/components/customers/Navbar";
import CystackFooter from "../components/layout/Footer";
import CystackHeader from "../components/layout/Header";
import CystackSmeHeader from "../components/layout/SmeHeader";
import Head from "next/head";
import LearnNavbar from "@/components/learn/Navbar";
import ModalJoinCyStackCommunity from "../components/CystackCommunity";
import TagManager from "react-gtm-module";
import { appWithTranslation } from "next-i18next";
import enUS from "antd/locale/en_US";
import { useRouter } from "next/router";
import viVN from "antd/locale/vi_VN";

const PhoneCall = () => {
  return (
    <a
      href="tel:+842471099656"
      className="fixed z-20 wrapper bottom-24 right-1"
    >
      <div className="shadow-none">
        <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
          <div className="coccoc-alo-ph-circle"></div>
          <div className="coccoc-alo-ph-circle-fill"></div>
          <div className="coccoc-alo-ph-img-circle"></div>
        </div>
      </div>
    </a>
  );
};

function CyStack({ Component, pageProps }) {
  const router = useRouter();

  const [isOpenCommunity, setIsOpenCommunity] = useState(false);
  const [subdomain, setSubdomain] = useState(null);
  const { locale } = router;

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-T2W558J" });
    const firstLink = sessionStorage.getItem("first_link");
    if (firstLink) return;
    sessionStorage.setItem("first_link", window.location.href);
  }, []);

  useEffect(() => {
    const isOpenedModalCommunity = sessionStorage.getItem(
      "is-opened-modal-community"
    );
    if (isOpenedModalCommunity || locale === "en") return;
    let timeout = setTimeout(() => {
      setIsOpenCommunity(true);
      const body = document.getElementsByTagName("body");
      body[0].style.setProperty("overflow", `hidden`, "important");
      sessionStorage.setItem("is-opened-modal-community", true);
    }, 5000);
    return () => {
      sessionStorage.removeItem("is-opened-modal-community");
      clearTimeout(timeout);
    };
  }, [locale]);

  const handleCloseModal = () => {
    setIsOpenCommunity(false);
    sessionStorage.setItem("is-opened-modal-community", true);
    const body = document.getElementsByTagName("body");
    body[0].style.setProperty("overflow", `auto`, "important");
  };

  useEffect(() => {
    if (window.$chatwoot) {
      window.$chatwoot.setLocale(locale);
    } else {
      if (window.chatwootSettings) {
        window.chatwootSettings.locale = locale;
      } else {
        window.chatwootSettings = {
          locale,
          baseDomain: ".cystack.net",
        };
      }
    }
  }, [locale]);

  useEffect(() => {
    const hostname = new URL(window.location.href).hostname;
    const parts = hostname.split(".");
    if (parts.length >= 2 && parts[0] === "sme") {
      setSubdomain(parts[0]);
    } else {
      setSubdomain(null);
    }
  }, []);

  return (
    <>
      <ConfigProvider
        locale={locale === "vi" ? viVN : enUS}
        theme={{
          hashed: false,
          token: {
            colorPrimary: "#2222FF",
            fontFamily: "Inter",
          },
        }}
      >
        <PhoneCall />
        {}
        {subdomain && subdomain === "sme" ? (
          <CystackSmeHeader />
        ) : !router.pathname.includes("/trust") || router.pathname.startsWith("/trust-center") ? (
          <CystackHeader />
        ) : null}
        {router.pathname.includes("/learn") && <LearnNavbar />}
        {(router.pathname.includes("/project") ||
          router.pathname.includes("/customer")) && <CustomerNavbar />}
        {router.pathname.includes("/careers") && <CareersNavbar />}
        {/* viewport meta tags should not be used in _document.js's <Head>. https://nextjs.org/docs/messages/no-document-viewport-meta. */}
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover"
          />
        </Head>
        <ModalJoinCyStackCommunity
          isOpen={isOpenCommunity}
          closeModal={handleCloseModal}
        />
        <Component {...pageProps} />
        {(!router.pathname.includes("/trust") || router.pathname.startsWith("/trust-center")) &&
        Object.keys(pageProps).length ? (
          <CystackFooter subdomain={subdomain} />
        ) : null}
      </ConfigProvider>
    </>
  );
}
export default appWithTranslation(CyStack);
