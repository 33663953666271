import ENDPOINT from "@/config/endpoint";
import request from "@/utils/request";

async function fetchCategory(params) {
  return await request({
    url: ENDPOINT.LEARN_CATEGORIES,
    method: "get",
    params
  });
}

async function fetchPosts(params) {
  return await request({
    url: ENDPOINT.LEARN_POST,
    method: "get",
    params,
  });
}

async function subscribe(data) {
  return await request({
    url: ENDPOINT.BLOG_SUBSCRIBE,
    method: "post",
    data,
  });
}

export default {
  fetchCategory,
  fetchPosts,
  subscribe
};