import { useEffect, useState } from "react";

import DirectionDown from "@/public/icons/direction-down.svg";
import Link from "next/link";
import learnServices from '@/services/learn'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useRouter } from "next/router";
import { useTranslation } from 'next-i18next'

export default function BlogNavbar() {
  const { t } = useTranslation('common')

  const router = useRouter()
  const [categories, setCategories] = useState([]);
  const [isActive, setIsActive] = useState('')
  const [showMenu, setShowMenu] = useState(false)
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await learnServices.fetchCategory()
        setCategories(() => {
          return response.filter((c) => c.count > 0)
        })
      } catch (error) {
        console.log('BlogNavbar error', error);
      }
    }

    fetchCategory()
  }, []);

  useEffect(() => {
    if (router.route === '/learn') {
      setIsActive('home')
    } else if (router.route.includes('/learn/category/[slug]')) {
      setIsActive(router.query.slug)
    } else {
      setIsActive('')
    }
  }, [router]);

  const closeMenu = () => {
    setShowMenu(false)
  }

  const ref = useDetectClickOutside({ onTriggered: closeMenu });

  return (
    <>
      <nav className="py-4 flex items-center md:justify-end relative">
        <Link href="/learn">
          <h5 className="w-fit absolute top-4 left-14 md:left-5 cursor-pointer z-[2]">{t('learn.title')}</h5>
        </Link>
        <div ref={ref} className="relative pr-14 md:pr-5 w-full">
          <div onClick={() => setShowMenu(!showMenu)} className="cursor-pointer md:flex items-center justify-end w-fit ml-auto hidden text-dark">
            <span>Menu</span>
            <DirectionDown className="w-6 h-6 ml-2" />
          </div>
          <ul className={`${showMenu ? 'flex-col' : 'md:hidden'} md:absolute top-[110%] right-5 md:bg-white md:shadow-md md:rounded flex-1 m-0 pl-40 md:pr-24 md:p-4 flex z-[2] items-center md:items-start justify-center gap-x-8 flex-wrap list-none`}>
            <Link href="/blog">
              <li className={`${isActive === 'home' ? 'font-semibold before:w-full opacity-100' : 'before:w-0 opacity-70'} md:whitespace-nowrap text-body4 cursor-pointer relative before:absolute before:-bottom-1 before:content-[''] before:bg-black before:h-[2px] before:w-0 hover:before:w-full before:transition-all hover:opacity-100 mb-6`}>Home</li>
            </Link>
            {categories.map((item) => (
              <Link key={item.id} href={`/learn/category/${item.slug}?id=${item.id}`}>
                <li dangerouslySetInnerHTML={{ __html: item.name }} className={`${isActive === item.slug ? 'font-semibold before:w-full opacity-100' : 'before:w-0 opacity-70'} md:whitespace-nowrap text-body4 cursor-pointer relative before:absolute before:-bottom-1 before:content-[''] before:bg-black before:h-[2px] before:w-0 hover:before:w-full before:transition-all hover:opacity-100 mb-6`}></li>
              </Link>
            ))}
          </ul>
        </div>
      </nav>
    </>
  )
}